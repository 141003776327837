 <template>
	<el-dialog top="20vh" title="设置收款人" width="500px" :visible.sync="this_is_show" @close="close">
        		<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="tel" placeholder="请输入收款人手机"  auto-complete="new-password"  clearable>
					<el-button style="width:100px" slot="prepend">收款人手机</el-button>
					<el-button  @click="se_tuck_owner_info" type="success" slot="append" style="width:112px;background-color:#409EFF;color:black;border-radius: 0;">搜索</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="check_info" placeholder="勾选信息" auto-complete="new-password"  disabled>
					<el-button style="width:100px" slot="prepend">勾选信息</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="name" placeholder="收款人姓名" auto-complete="new-password"  disabled>
					<el-button style="width:100px" slot="prepend">收款人姓名</el-button>
				</el-input>
			</el-form-item>
            <!-- <el-form-item>
				<el-input v-model="pwd2" placeholder="银行卡号" auto-complete="new-password" disabled>
					<el-button style="width:100px" slot="prepend">银行卡号</el-button>
				</el-input>
			</el-form-item> -->
            <el-form-item>
                <div class="button">
                     <!-- <el-button @click="dialogFormVisible=false">解除绑定</el-button> -->
                     <el-button type="primary" @click="set_sub_price_confirm">立即提交</el-button>
                </div>
               
           </el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { Loading } from 'element-ui';
	import {mapState} from 'vuex'
	export default {
		props:{
			data:Array,
			is_show:Number,
			check_info:String,
		},
		data() {
			return {

                tel:'',//收款人手机
                name:'',//收款人姓名
				//页面是否显示
				this_is_show:false,

                tord_list:null,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
                    this.this_is_show=true
				}
			},
            data(new_data){
                 this.tord_list = new_data
            }
		},
		computed:{
			...mapState(["user_info"])
		},
		methods: {
            set_sub_price_confirm(){
                if(!this.tel){
                    this.$my.other.msg({
                        type:'info',
                        str:'收款人信息为空'
                    })
                    return
                }
                let truck_tord_nums = []
                let truck_cost_list  = []
                //遍历数据
				this.tord_list.forEach(item=>{
					item.truck_tord_num = item.truck_tord_num.trim()
					truck_tord_nums.push(item.truck_tord_num)
					truck_cost_list.push({
						truck_tord_num:item.truck_tord_num,
						give_truck_owner_total:item.cost_info.give_truck_owner_total,
						give_driver_total:item.cost_info.give_driver_total,
						truck_owner_num:this.user_num,
                        system_profit:0,
						freight_total:item.cost_info.freight_total
					})
				})
                //修改数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_cost_edit_by_sub_user',
						truck_tord_nums,
						truck_cost_list,
					},callback:(data)=>{
                        this.$my.other.msg({
							type:'success',
							str:'编辑成功'
					    })
                        this.this_is_show = false
                        this.$emit("edited");
					}
				})
            },
			se_tuck_owner_info(){
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'user_list_by_app_user',
                        tels:[this.tel],
                        num:1000
                    },callback:(data)=>{
                        if(data.list.length==0){
                            this.$my.other.msg({
                                type:'info',
                                str:'未查找到收款人信息'
                            })
                            return
                        }
                        this.name = data.list[0].name
                        this.user_num = data.list[0].user_num
                    }
                })
            },
		   close(){
               this.tel = ''
               this.name = ''
               this.user_num = ''
               this.tord_list = null
           },
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		
		border-radius: 50%;
		color:rgb(0, 0, 0);
		.shou{
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: red;
	}
	.green{
		background-color: #4CD964;
	}
    .button{
        text-align: center;
    }
</style>